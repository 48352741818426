// eslint-disable-next-line import/no-unresolved
import { Overrides } from '@material-ui/core/styles/overrides';

const MuiButtonOverrides: Overrides['MuiButton'] = {
  root: {
    height: 36,
    borderRadius: 0,
    whiteSpace: 'nowrap',

    '&:not(:active)': {
      boxShadow: 'none',
    },
  },
  sizeLarge: {
    height: 45,
  },
  sizeSmall: {
    height: 30,
  },
};

export default MuiButtonOverrides;
