import { InMemoryCache } from '@apollo/client';

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        chatMessages: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = { data: [] }, incoming) {
            const currentData =
              incoming?.paginatorInfo?.currentPage === 1
                ? incoming?.data
                : existing?.data?.concat(...(incoming?.data ?? []));
            return {
              paginatorInfo: incoming?.paginatorInfo,
              data: currentData,
            };
          },
        },
        talents: {
          keyArgs: false,
          merge(_, incoming) {
            return {
              paginatorInfo: incoming?.paginatorInfo,
              data: incoming?.data ?? [],
            };
          },
        },
        currentCompanyUser: {
          keyArgs: false,
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

export default cache;
