import { gql } from '@apollo/client';

export default gql`
  query GetTalents(
    $search: String
    $category_ids: [Int]
    $skills_ids: [Int]
    $max_rate: Int
    $min_rate: Int
    $source_type: SourceTypeEnum
    $is_active: Boolean
    $first: Int = 10
    $page: Int
    $available_now: Boolean
  ) {
    talents(
      search: $search
      category_ids: $category_ids
      skills_ids: $skills_ids
      max_rate: $max_rate
      min_rate: $min_rate
      source_type: $source_type
      is_active: $is_active
      first: $first
      available_now: $available_now
      page: $page
    ) {
      paginatorInfo {
        lastPage
        currentPage
        hasMorePages
      }
      data {
        id
        is_invitation_accepted
        first_name
        last_name
        address
        rate
        recent_position_title
        email
        available_now
        location
        avatar {
          avatar
        }
        talent_data {
          phone
        }
      }
    }
  }
`;

export const GET_TALENT_CATEGORIES = gql`
  query GetTalentCategories {
    talentCategories {
      id
      name
    }
  }
`;
