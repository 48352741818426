import cn from 'classnames';
import { SnackbarProvider as BaseSnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';

type SnackbarProviderProps = PropsWithChildren<Record<string, unknown>>;

const SnackbarProvider = (props: SnackbarProviderProps) => {
  const { children, ...rest } = props;
  const classes = useStyles(props);
  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: string) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <BaseSnackbarProvider
      {...rest}
      maxSnack={4}
      ref={notistackRef}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      action={(key: string) => (
        <IconButton
          className={classes.buttonIcon}
          size="small"
          onClick={onClickDismiss(key)}
        >
          <CloseIcon className={classes.buttonIcon} fontSize="small" />
        </IconButton>
      )}
      classes={{
        variantSuccess: cn(classes.root, classes.variantSuccess),
        variantError: cn(classes.root, classes.variantError),
        variantWarning: cn(classes.root, classes.variantWarning),
        variantInfo: cn(classes.root, classes.variantInfo),
      }}
    >
      {children}
    </BaseSnackbarProvider>
  );
};

export default SnackbarProvider;
