import React from 'react';

import { Box } from '@material-ui/core';

import { ConnectedPageLayout } from 'components/layout/page-layout';
import Typography from 'components/typography';

const NotFound = () => {
  return (
    <ConnectedPageLayout
      documentTitle="Page not found"
      headerProps={{ accountProps: {} }}
      drawerProps={{}}
    >
      <Box pt="60px">
        <Typography variant="h1">404.</Typography>
        <Typography variant="h3">Page not found</Typography>
      </Box>
    </ConnectedPageLayout>
  );
};

export default NotFound;
