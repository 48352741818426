import { SimplePaletteColorOptions } from '@material-ui/core/styles';

import { PALETTE } from './palette';
import { TYPOGRAPHY_THEME_OPTIONS } from './typography';

export const CHIP_THEME_OPTIONS = {
  MuiChip: {
    root: {
      padding: '4px 14px',
      backgroundColor:
        (PALETTE.success as SimplePaletteColorOptions).main ?? '#26CF85',
    },
    sizeSmall: {
      height: 22,
      padding: '0 8px',
    },
    label: {
      ...TYPOGRAPHY_THEME_OPTIONS.caption,
      paddingLeft: 0,
      paddingRight: 0,
    },
    labelSmall: {
      ...TYPOGRAPHY_THEME_OPTIONS.caption,
      paddingLeft: 0,
      paddingRight: 0,
    },
    icon: {
      height: 24,
      width: 24,
      marginLeft: -5,
      marginRight: 5,
    },
    iconSmall: {
      height: 18,
      width: 18,
      marginLeft: -5,
      marginRight: 5,
    },
    deleteIconSmall: {
      height: 18,
      width: 18,
      marginLeft: 5,
      marginRight: -5,
    },
  },
};
