import { gql } from '@apollo/client';

export default gql`
  mutation CreateTalentProfile(
    $user_id: Int!
    $generalInfo: TalentGeneralInfoInput!
    $email: Email!
    $is_ot_pool: Boolean
  ) {
    createTalentProfile(
      user_id: $user_id
      generalInfo: $generalInfo
      email: $email
      is_ot_pool: $is_ot_pool
    )
  }
`;
