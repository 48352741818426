import cn from 'classnames';
import React from 'react';

import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core/Typography';

import { CUSTOM_COLORS, CUSTOM_VARIANTS } from './consts';
import useStyles from './styles';
import { CustomColors, CustomVariants } from './types';

export interface TypographyProps
  extends Omit<MuiTypographyProps, 'variant' | 'color'> {
  variant?: MuiTypographyProps['variant'] | CustomVariants;
  transform?: React.CSSProperties['textTransform'];
  color?: MuiTypographyProps['color'] | CustomColors;
  component?: string;
}

const Typography = (props: TypographyProps) => {
  const { variant, className, color, ...rest } = props;
  const styles = useStyles(props);
  const isCustomVariant = !!variant && CUSTOM_VARIANTS.includes(variant as any);
  const isCustomColor = !!color && CUSTOM_COLORS.includes(color as any);
  const currentVariant = (isCustomVariant
    ? undefined
    : variant) as MuiTypographyProps['variant'];
  const currentColor = (isCustomColor
    ? undefined
    : color) as MuiTypographyProps['color'];

  return (
    <MuiTypography
      {...(isCustomVariant && { component: 'span' })}
      {...rest}
      className={cn(className, styles.text, {
        [styles[variant as CustomVariants]]: isCustomVariant,
        [styles[color as CustomVariants]]: isCustomColor,
      })}
      variant={currentVariant}
      color={currentColor}
    />
  );
};

Typography.displayName = 'CustomTypography';

export default Typography;
