import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as GroupsIcon } from 'assets/icons/groups.svg';
import { ReactComponent as InviteIcon } from 'assets/icons/invite.svg';
import { ReactComponent as ProjectsIcon } from 'assets/icons/projects.svg';
import { ReactComponent as UserProfileIcon } from 'assets/icons/user_profile.svg';
import { DrawerListItemProps } from 'components/layout/drawer/drawer-list-item';
import { LINKS } from 'routes/consts';

export const DEFAULT_TALENT_ITEMS: DrawerListItemProps[] = [
  { link: LINKS.talent.profile, text: 'Profile', Icon: UserProfileIcon },
  { link: LINKS.talent.jobBoard, text: 'Job Board', Icon: ProjectsIcon },
  { link: LINKS.talent.groups, text: 'Groups', Icon: GroupsIcon },
  { link: LINKS.talent.invite, text: 'Invite & Earn', Icon: InviteIcon },
];
export const DEFAULT_COMPANY_ITEMS: DrawerListItemProps[] = [
  { link: LINKS.company.dashboard, text: 'Dashboard', Icon: DashboardIcon },
  { link: LINKS.company.workforce, text: 'My Workforce' },
  { link: LINKS.company.jobBoard, text: 'Job Board', Icon: ProjectsIcon },
  { link: LINKS.company.createProfile, text: 'Invite', Icon: InviteIcon },
];
export const NOT_ACCEPTED_TALENT_ITEMS = [
  {
    link: LINKS.talent.editProfile,
    text: 'Profile',
    Icon: UserProfileIcon,
  },
];
