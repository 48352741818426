import { useApolloClient } from '@apollo/client';
import * as AuthUtils from 'auth0/utils';

import { Query } from 'generated/graphql-types';
import { GET_CURRENT_TALENT } from 'graphql/talents';
import { GET_CURRENT_COMPANY_USER } from 'graphql/user';

import { useAuth0 } from './useAuth0';

const useCurrentUser = () => {
  const apolloClient = useApolloClient();
  const { user: auth0User } = useAuth0();
  const isTalent = AuthUtils.isTalent(auth0User);
  const isCompany = AuthUtils.isCompany(auth0User);

  const data = apolloClient.readQuery<Query>({
    query: isTalent ? GET_CURRENT_TALENT : GET_CURRENT_COMPANY_USER,
  });

  return { data, isTalent, isCompany };
};

export default useCurrentUser;
