import { gql } from '@apollo/client';

export default gql`
  query CurrentCompanyUser {
    currentCompanyUser {
      id
      email
      first_name
      last_name
      position
      company {
        id
        name
        logo
      }
      created_at
      updated_at
      avatar {
        avatar
        hash
      }
    }
  }
`;
