import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'themes/default';

import { TypographyProps } from './Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      textTransform: (props: TypographyProps) => props.transform || 'inherit',
    },
    tertiary: {
      color: theme.palette.tertiary.main,
    },
    buttonLarge: {
      ...theme.typography.button,
      fontSize: 15,
      lineHeight: '26px',
    },
    buttonSmall: {
      ...theme.typography.button,
      fontSize: 14,
      lineHeight: '24px',
    },
    captionSmall: {
      ...theme.typography.caption,
      fontSize: 12,
      lineHeight: '18px',
    },
  }),
);

export default useStyles;
