export const PROJECT_NAME = 'Opentalent';
export const API_URI = process.env.REACT_APP_GQL_PATH;

export const isDev = process.env.NODE_ENV === 'development';

export const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const RESPONSE_TYPE = process.env.REACT_APP_AUTH0_RESPONSE_TYPE;
export const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const SCOPE = 'openid profile';
export const REDIRECT_URI = isDev
  ? process.env.REACT_APP_AUTH0_REDIRECT_URI
  : `${window.location.origin}/auth0`;
