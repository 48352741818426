import { DrawerProps } from 'components/layout/drawer';
import { HeaderProps } from 'components/layout/header';
import { Talent, User } from 'generated/graphql-types';
import { useAuth0 } from 'hooks/auth/useAuth0';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { formatName } from 'utils/talent';

import {
  DEFAULT_TALENT_ITEMS,
  DEFAULT_COMPANY_ITEMS,
  NOT_ACCEPTED_TALENT_ITEMS,
} from './consts';

const getDrawerItems = ({
  isTalent,
  isAccepted,
}: {
  isTalent: boolean;
  isAccepted: boolean;
}) => {
  if (isTalent && !isAccepted) {
    return NOT_ACCEPTED_TALENT_ITEMS;
  }

  return isTalent ? DEFAULT_TALENT_ITEMS : DEFAULT_COMPANY_ITEMS;
};

export const useLayoutProps = (): {
  connectedDrawerProps: DrawerProps;
  connectedHeaderProps: HeaderProps;
} => {
  const { user: auth0User } = useAuth0();
  const { data, isTalent } = useCurrentUser();
  const user = data?.currentTalent ?? data?.currentCompanyUser;

  const firstName = user?.first_name ?? auth0User?.given_name;
  const lastName = user?.last_name ?? auth0User?.family_name;
  const accountProps = {
    name: formatName({ firstName, lastName }),
    avatar: (user as Talent)?.avatar?.avatar ?? auth0User?.picture,
    position: isTalent
      ? (user as Talent)?.source?.name
      : (user as User)?.company?.name || 'Company user',
  };
  const connectedDrawerProps = {
    items: getDrawerItems({
      isTalent,
      isAccepted: (user as Talent)?.is_invitation_accepted ?? false,
    }),
  };

  const connectedHeaderProps: HeaderProps = { accountProps, inventionCount: 1 };

  return { connectedHeaderProps, connectedDrawerProps };
};
