import React from 'react';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const DefaultContentSkeleton = () => (
  <>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Skeleton width="90%" height="30px" variant="rect" />
      </Grid>
      <Grid item>
        <Skeleton width="100%" height="30px" variant="rect" />
      </Grid>
      <Grid item>
        <Skeleton width="80%" height="30px" variant="rect" />
      </Grid>
      <Grid item>
        <Skeleton width="40%" height="30px" variant="rect" />
      </Grid>
    </Grid>
    <br />
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Skeleton width="90%" height="30px" variant="rect" />
      </Grid>
      <Grid item>
        <Skeleton width="100%" height="30px" variant="rect" />
      </Grid>
      <Grid item>
        <Skeleton width="80%" height="30px" variant="rect" />
      </Grid>
      <Grid item>
        <Skeleton width="40%" height="30px" variant="rect" />
      </Grid>
    </Grid>
  </>
);

export default DefaultContentSkeleton;
