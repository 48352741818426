/* eslint-disable  @typescript-eslint/no-unused-vars */
import {
  createMuiTheme,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles';

import MuiButtonOverrides from './MuiButtonOverrides';
import { ALERT_THEME_OPTIONS } from './alert';
import { CARD_THEME_OPTIONS } from './card';
import { CHECKBOX_THEME_OPTIONS } from './checkbox';
import { CHIP_THEME_OPTIONS } from './chip';
import { INPUT_THEME_OPTIONS } from './input';
import { PALETTE } from './palette';
import { TRANSITION_THEME_OPTIONS } from './transitions';
import { TYPOGRAPHY_THEME_OPTIONS } from './typography';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    other: Record<string, string>;
    tertiary: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    other: Record<string, string>;
    tertiary: SimplePaletteColorOptions;
  }
}

export const themeParams = {
  overrides: {
    MuiButton: MuiButtonOverrides,
    MuiCssBaseline: {
      '@global': {
        'body a': {
          textDecoration: 'none',
          color: 'inherit',
        },
      },
    },
    MuiTypography: {},
    MuiBadge: {
      dot: {
        height: 12,
        width: 12,
        lineHeight: '12px',
        borderRadius: '100%',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiListItemText: {
      secondary: {
        ...TYPOGRAPHY_THEME_OPTIONS.subtitle2,
        color:
          (PALETTE.secondary as SimplePaletteColorOptions).contrastText ??
          'white',
        opacity: '.8',
      },
      primary: {
        ...TYPOGRAPHY_THEME_OPTIONS.subtitle2,
        color:
          (PALETTE.secondary as SimplePaletteColorOptions).contrastText ??
          'white',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    ...INPUT_THEME_OPTIONS,
    ...CHECKBOX_THEME_OPTIONS,
    ...CHIP_THEME_OPTIONS,
    ...CARD_THEME_OPTIONS,
    ...ALERT_THEME_OPTIONS,
  },
  spacing: 4,
  typography: TYPOGRAPHY_THEME_OPTIONS,
  palette: PALETTE,
  transitions: TRANSITION_THEME_OPTIONS,
};

const theme = createMuiTheme(themeParams);

export default theme;
export type Theme = typeof theme;
