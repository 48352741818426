/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloProvider as BaseApolloProvider,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useSnackbar } from 'notistack';
import React from 'react';

import { API_URI, isDev } from 'consts/config';
import { useAuth0 } from 'hooks/auth/useAuth0';

import cache from './cache';

const useHttpLink = () => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    let token = '';
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      console.error(e);
    }

    return {
      headers: {
        ...headers,
        ...(token && { authorization: `Bearer ${token}` }),
      },
    };
  });
  return authLink.concat(createUploadLink({ uri: API_URI }));
};

const useErrorLink = () => {
  const { enqueueSnackbar } = useSnackbar();
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        enqueueSnackbar(message, { variant: 'error' });
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });
};

const useApolloClient = () => {
  const httpLink = useHttpLink();
  const errorLink = useErrorLink();

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    uri: API_URI,
    cache,
    connectToDevTools: isDev,
  });
};

const ApolloProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const client = useApolloClient();
  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};

export default ApolloProvider;
