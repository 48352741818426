import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'themes/default';

import { CONTENT_MAX_WIDTH, DRAWER_WIDTH } from '../consts';
import { PageLayoutProps } from './PageLayout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      background: '#EDEDED',
    },
    contentWrapper: {
      padding: ({ contentSpacing = 4 }: PageLayoutProps) =>
        theme.spacing(contentSpacing),
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: CONTENT_MAX_WIDTH,

      margin: ({ centered }: PageLayoutProps) =>
        centered ? '0 auto' : 'inherit',
    },

    contentSkeleton: {
      height: '100%',
      width: '100%',
      flexGrow: 1,
    },
  }),
);

export default useStyles;
