import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Landing from 'screens/landing';
import NotFound from 'screens/not-found';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact component={Landing} path="/" />
        {/* Else */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
