import { PALETTE } from './palette';
import { TYPOGRAPHY_THEME_OPTIONS } from './typography';

export const ALERT_THEME_OPTIONS = {
  MuiAlert: {
    root: {
      borderRadius: 0,
    },
    filledInfo: {
      backgroundColor: PALETTE.other.black ?? '#282824',
    },
    message: {
      padding: '6px 0',
      ...TYPOGRAPHY_THEME_OPTIONS.body1,
    },
  },
};
