import { Typography } from '@material-ui/core/styles/createTypography';

const FONT_WEIGHTS = {
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
};

export const TYPOGRAPHY_THEME_OPTIONS: Typography = {
  fontSize: 14,
  pxToRem: (px) => `${px}px`,
  fontFamily: 'Poppins, sans-serif',
  fontWeightLight: FONT_WEIGHTS.LIGHT,
  fontWeightRegular: FONT_WEIGHTS.REGULAR,
  fontWeightMedium: FONT_WEIGHTS.MEDIUM,
  fontWeightBold: FONT_WEIGHTS.BOLD,

  h1: {
    fontSize: 96,
    fontWeight: FONT_WEIGHTS.LIGHT,
    lineHeight: '112px',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontSize: 60,
    fontWeight: FONT_WEIGHTS.LIGHT,
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontSize: 48,
    fontWeight: FONT_WEIGHTS.REGULAR,
    lineHeight: '56px',
  },
  h4: {
    fontSize: 34,
    fontWeight: FONT_WEIGHTS.REGULAR,
    lineHeight: '36px',
  },
  h5: {
    fontSize: 24,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '32px',
    letterSpacing: '0.18px',
  },
  h6: {
    fontSize: 20,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.REGULAR,
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  body1: {
    fontSize: 16,
    fontWeight: FONT_WEIGHTS.REGULAR,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  body2: {
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.REGULAR,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  button: {
    fontSize: 14,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '20px',
    letterSpacing: '1.25px',
  },
  caption: {
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    lineHeight: '18px',
    letterSpacing: '0.4px',
  },
  overline: {
    fontSize: 12,
    fontWeight: FONT_WEIGHTS.REGULAR,
    lineHeight: '18px',
    letterSpacing: '0.4px',
    textTransform: 'none',
  },
};
