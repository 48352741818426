import {
  Auth0ContextInterface,
  LogoutOptions,
  useAuth0 as baseUseAuth0,
} from '@auth0/auth0-react';
import { useCallback } from 'react';

import { UserInterface } from '../../auth0/types';

interface CustomAuth0ContextInterface extends Auth0ContextInterface {
  user: UserInterface;
}

export const useAuth0 = () => {
  const params = baseUseAuth0();
  const logout = useCallback(
    (opts?: LogoutOptions) => {
      params.logout({ returnTo: window.location.origin, ...opts });
    },
    [params],
  );

  return { ...params, logout } as CustomAuth0ContextInterface;
};
