export const LINKS = {
  auth: '/',
  auth0: '/auth0',
  logout: '/logout',
  company: {
    workforce: '/company',
    dashboard: '/dashboard',
    createProfile: '/company/create-profile',
    talentProfile: '/company/talent-profile',
    settings: '/company/settings',
    jobBoard: '/company/job-board',
    job: '/company/job/:id',
    editJob: '/company/edit-job/:id',
    newJob: '/company/new-job',
  },
  talent: {
    profile: '/talent',
    groups: '/talent/groups',
    editProfile: '/talent/edit-profile',
    jobBoard: '/talent/job-board',
    invite: '/invites',
    onboarding: {
      form: '/talent/onboarding/form',
      intro: '/talent/onboarding/intro',
    },
  },
};
