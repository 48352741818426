import Auth0Provider from 'auth0/Auth0Provider';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

import SnackbarProvider from 'components/snackbar/SnackbarProvider';
import ApolloProvider from 'graphql/client/ApolloProvider';
import Router from 'routes';
import defaultTheme from 'themes/default';
import { setYupLocale } from 'utils/yup';

setYupLocale();

ReactDOM.render(
  <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <SnackbarProvider>
          <ApolloProvider>
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: {
                  variant: 'contained',
                  color: 'primary',
                },
              }}
            >
              <CssBaseline />
              <Router />
            </ConfirmProvider>
          </ApolloProvider>
        </SnackbarProvider>
      </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
