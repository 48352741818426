import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const PALETTE: PaletteOptions = {
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.54)',
  },
  primary: {
    main: '#F2FF88',
    light: '#FFFF8A',
    dark: '#D1D100',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    main: '#191919',
    dark: '#000',
    light: '#3D3D3D',
    contrastText: '#FFF',
  },
  tertiary: {
    main: '#F7B34F',
    dark: '#F7B34F',
    light: '#F7B34F',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrastText: '#FFF',
  },
  error: {
    main: '#EC634E',
    dark: '#E73C22',
    light: '#F39E90',
    contrastText: '#FFF',
  },
  warning: {
    main: '#F2A87E',
    dark: '#ED884F',
    light: '#F8D3BE',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  success: {
    main: '#26CF85',
    dark: '#1EA469',
    light: '#5BE1A6',
    contrastText: 'rgba(0,0,0,87)',
  },
  other: {
    main: 'rgba(25, 25, 25, 0.08)',
    dark: 'rgba(25, 25, 25, 0.24)',
    light: 'rgba(0, 0, 0, 0.26)',
    contrastText: 'rgba(30, 30, 28, 0.24)',
    lightBlue: '#B9F3FA',
    lightDark: '#40403E',
    black: '#282824',
    green: '#26CFBB',
  },
};
